.integration-steps {
    margin: 20px;
  }
  
  .integration-steps__list {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .integration-steps__list-item {
    margin-bottom: 10px;
  }
  
  .facebook-login {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }
  
  .facebook-login .fb-login-button {
    /* Estilos adicionais para o botão de login do Facebook, se necessário */
  }
  .current-token{
    
  }
  