.react-bootstrap-table {
  height: 80vh;
  overflow-y: scroll;
  width: 100%;
}

.react-bootstrap-table-pagination {
  width: 100%;
  z-index: 1;
  background-color: #fff;
  margin-top: 2rem;
  position: relative;
}

._loading_overlay_overlay {
  height: calc(100% - 2rem) !important;
  z-index: 0;
}